import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import "./PrivacyPolicy.css";


export const PrivacyPolicy = () => {
  const [markdown, setMarkdown] = useState<string>();

  useEffect(() => {
    fetch('./Aurora Compass Privacy Policy.md') // Markdown file is fetched from public folder
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, [])

  return (
    <div className="basic-canvas">
      <div className="basic-column" style={{ marginTop: "1.2em" }}>
        <div className="privacy-policy">
          <ReactMarkdown>{markdown}</ReactMarkdown>
        </div>
      </div>
    </div>
  )
}